import React from "react";

import { Link as RouterLink } from "react-router-dom";
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Badge,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// utils

// components
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";

// ----------------------------------------------------------------------

const ColorBox = ({ color }) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        width: "16px",
        height: "16px",
        borderRadius: "3px",
        margin: "2px",
      }}
    />
  );
};

const ProductImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",

  objectFit: "contain",
  position: "absolute",
});

// ----------------------------------------------------------------------

export default function AnnonceCard({ ads, handleClickOpenDialog }) {
  const {
    id,
    title,
    filePath,
    status,
    annonceAttributes,
    category,
    stockage,
    date,
    ville,
  } = ads;
  const frenchdate = new Date(date);

  const annonceDate = frenchdate.toLocaleString("fr-FR", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const cover = category.isImageFrontHidden
    ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${category.defaultImage}`
    : filePath === null
    ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${category.defaultImage}`
    : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${filePath}`;

  return (
    <Card
      onClick={() => handleClickOpenDialog(ads)}
      sx={{
        cursor: "pointer",
      }}
    >
      <Box sx={{ pt: "100%", position: "relative" }}>
        {/* {status && (
         
          <Badge>
            {status}
          </Badge>
            
 
        )} */}
        <ProductImgStyle alt={title} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography
          sx={{ display: "flex", justifyContent: "space-between" }}
          variant="subtitle"
          noWrap
        >
          <Typography variant="h5"> {category.name} </Typography>
          {/* <Chip label={id} color="primary" /> */}
        </Typography>

        <Divider />

        <Stack>
          <Typography variant="subtitle1">
            <Typography component="span" variant="body1"></Typography>
          </Typography>

          <List sx={{ padding: 0, margin: 0 }}>
            <ListItem sx={{ padding: 0, margin: 0 }}>
              <ListItemIcon>
                <PlaceIcon />
              </ListItemIcon>
              <ListItemText primary={"Les Trans Musicales"} sx={{ padding: 0, margin: 0 }} />
            </ListItem>
          </List>

          <List sx={{ padding: 0, margin: 0 }}>
            <ListItem sx={{ padding: 0, margin: 0 }}>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText
                primary={annonceDate}
                sx={{ padding: 0, margin: 0 }}
              />
            </ListItem>
          </List>

          <Typography>Détails</Typography>
          <Divider />
          {annonceAttributes
            .filter((att) => att.isSecret === false)
            .map((annonceAttribute, index) => (
              <List key={index} sx={{ padding: 0, margin: 0 }}>
                <ListItem sx={{ padding: 0, margin: 0 }}>
                  <ListItemText
                    className="details"
                    primary={annonceAttribute.uiLabel}
                    secondary={
                      annonceAttribute.uiType === "colorcheckbox" ? (
                        <ColorBox color={annonceAttribute.uiValue} />
                      ) : (
                        annonceAttribute.attributeValue
                      )
                    }
                    sx={{ padding: 0, margin: 0 }}
                  />
                </ListItem>
                <Divider />
              </List>
            ))}
        </Stack>
        {/* <ShButton variant="contained"  onClick={handleClickOpenDialog.bind(this, ads)}>Détail</ShButton> */}
      </Stack>
    </Card>
  );
}
