import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Chat from "./Components/Chat/Chat";
import AuthContext from "./contexts/AuthContext";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Found from "./Pages/found/Found";
import Homepage from "./Pages/Homepage/Homepage";
import Login from "./Pages/Login/Login";
import Publier from "./Pages/publier/Publier";
import AuthAPI from "./services/authAPI";
import axios from "axios";
import Products from "./Pages/Dashboard/Products";
import "./global.css";
import HomeCustomers from "./Pages/Customers/HomeCustomers";

import Register from "./Pages/Login/auth/login/Register";
import Validate from "./Pages/Login/auth/Validate";
import Message from "./Pages/found/Message";
import ResetPassword from "./Pages/Login/auth/ResetPassword";

AuthAPI.setup();  
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthAPI.isAuthenticated
  );
  const [notifications, setNotifications] = useState([]);
  const [user, setUser] = useState([]);

  const getUser = async () => {
    try {
      const data = await axios
        .get(process.env.REACT_APP_PUBLIC_API + "/me")
        .then((response) => {
          setUser(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue = {
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser,
    notifications,
    setNotifications,
  };

 


  useEffect(() => {
  
    setIsAuthenticated( AuthAPI.isAuthenticated);
    setUser(getUser());
    const id = setInterval(fetchNotifications, 30000);
    return () => clearInterval(id);
  }, []);

  const fetchNotifications = async () => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/notifications`;
    try {
      const data = await axios.get(ApiUrl).then((response) => response.data);
      setNotifications(data);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {});
  // }, []);

  return (
    <AuthContext.Provider value={contextValue}>
      <Routes>
        {/* <Route path="/" element={<Homepage />} /> */}
        <Route path="/" element={<Found status="TROUVÉ" customerId={5} />} />
        <Route
          path="/publier"
          element={
            isAuthenticated ? <Publier /> : <Navigate replace to="/login" />
          }
        />
        <Route
          path="/publier-trouve"
          element={
            isAuthenticated ? (
              <Publier status="TROUVÉ" />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />
        <Route
          path="/publier-perdu"
          element={
            isAuthenticated ? (
              <Publier status="PERDU" />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />

        <Route
          path="/objets-partenaires/:customerId/"
          element={<Found status="TROUVÉ" />}
        />
        <Route path="/objets-trouves" element={<Found status="TROUVÉ" />} />
        <Route path="/objets-perdus" element={<Found status="PERDU" />} />

        <Route path="/login" element={<Login />} />
        <Route path="/login/:redeem" element={<Login />} />

        <Route
          path="/mon-compte"
          element={
            isAuthenticated ? <Dashboard /> : <Navigate replace to="/login" />
          }
        />
        <Route
          path="/mes-annonces"
          element={
            isAuthenticated ? <Products /> : <Navigate replace to="/login" />
          }
        />
        <Route
          path="/messages"
          element={
            isAuthenticated ? <Chat /> : <Navigate replace to="/login" />
          }
        />

        <Route path="/partenaires" element={<HomeCustomers id={2} />} />
        <Route path="/inscription" element={<Register />} />
        <Route path="/activation/:token" element={<Validate />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route
          path="/message/:annonce"
          element={
            isAuthenticated ? <Message /> : <Navigate replace to="/login" />
          }
        />
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
