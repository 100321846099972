import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import authAPI from "../../services/authAPI";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import Logo from "./Logo";
import NotificationsPopover from "./NotificationsPopover";
import BasicMenu from "./BasicMenu";
import DrawerComp from "./DrawerComp";

export default function Navbar({ linkColor = "#333", logo = false }) {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, setUser } = useContext(
    AuthContext
  );

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    authAPI.logout();
    setIsAuthenticated(false);
    setUser([]);
    navigate("/");
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>
        Mes Informations personnelles !
      </MenuItem> */}
      <Link to="/mon-compte">
        <MenuItem onClick={handleMenuClose}>Mon compte</MenuItem>
      </Link>
      <MenuItem onClick={handleLogout}>
        <PowerSettingsNewIcon /> Déconnexion
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
    
      <MenuItem>
        <Link to="/objets-trouves" style={{ textDecoration: "none" }}>
          <Typography>objets trouvés</Typography>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/objets-perdus" style={{ textDecoration: "none" }}>
          <Typography>objets perdus</Typography>
        </Link>
      </MenuItem>
      <MenuItem>
        <BasicMenu />
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="notifications"
          color="inherit"
        ></IconButton>
        <NotificationsPopover />
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {logo && <Logo />}

    
          <AppBar color="transparent" position="static" elevation={0}>
            <Toolbar>
            <Link to="/" style={{ textDecoration: "none" }}>
                <Button sx={{ my: 2, color: "#1A997A", display: "block" }}>
                  LES TRANS 22
                </Button>
              </Link>
              {/* <Button sx={{ my: 2, color: linkColor, display: "block" }}>
                Comment ça marche ?
              </Button>
              <Link to="/objets-trouves" style={{ textDecoration: "none" }}>
                <Button sx={{ my: 2, color: linkColor, display: "block" }}>
                  objets trouvés
                </Button>
              </Link>
              <Link to="/objets-perdus" style={{ textDecoration: "none" }}>
                <Button sx={{ my: 2, color: linkColor, display: "block" }}>
                  objets perdus
                </Button>
              </Link>
               <BasicMenu /> */}
              {!isAuthenticated && (
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Button sx={{ my: 2, color: linkColor, display: "block" }}>
                    Connexion
                  </Button>
                </Link>
              )}

              {isAuthenticated && (
                <>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ display: { xs: "none", md: "flex" } }}>
                    <IconButton
                      size="large"
                      aria-label="show mails"
                      color="inherit"
                    ></IconButton>
                    <NotificationsPopover />
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: { xs: "flex", md: "none" } }}>
                    <IconButton
                      size="large"
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Toolbar>
          </AppBar>
        

         {renderMobileMenu}
        {renderMenu} 
      </Box>
    </>
  );
}
