import { Button, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import Layout from "../../Components/layout/Layout";
import DashboardBox from "./DasboardBox";

import AuthContext from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faNewspaper,
  faUser,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import authAPI from "../../services/authAPI";
import { useEffect } from "react";
import axios from "axios";
import { ContactSupportOutlined } from "@mui/icons-material";
import { set } from "date-fns/esm";

export default function Dashboard() {
  const { user, setUser, isAuthenticated, setIsAuthenticated } = useContext(
    AuthContext
  );

  const navigate = useNavigate();

  const getUser = async () => {
    try {
      const data = await axios
        .get(process.env.REACT_APP_PUBLIC_API + "/me")
        .then((response) => {
          setUser(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!authAPI.isAuthenticated) {
      navigate("/login");
    }
    setIsAuthenticated(authAPI.isAuthenticated);
    getUser();
  }, []);
  return (
    <Layout>
      {user && (
        <Container>
          <Typography variant="h3">Bonjour {user.firstname}</Typography>

          <Link to="/publier" style={{ textDecoration: "none" }}>
            <Button
              sx={{
                marginY: 5,
                backgroundColor: "#4ABDF0",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#4ABDF0",
                  border: "1px solid #4ABDF0",
                },
              }}
            >
              Publier une Annonce
            </Button>
          </Link>

          <Grid container spacing={2}>
            <Grid>
              <DashboardBox link="/mes-annonces">
                <FontAwesomeIcon icon={faNewspaper} />
                <Typography>Mes Annonces</Typography>
              </DashboardBox>
            </Grid>
            {/* <Grid>
              <DashboardBox link="/mon-profil">
                <FontAwesomeIcon icon={faUser} />
                <Typography>Mon profil</Typography>
              </DashboardBox>
            </Grid> */}

            <Grid>
              <DashboardBox link="/messages">
                <FontAwesomeIcon icon={faMessage} />
                <Typography>Ma messagerie</Typography>
              </DashboardBox>
            </Grid>

            {/* <Grid>
              <DashboardBox link="/">
                <FontAwesomeIcon icon={faTruck}  />
                <Typography>Livraison</Typography>
              </DashboardBox>
            </Grid> */}
          </Grid>
        </Container>
      )}
    </Layout>
  );
}
